import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  IupicsCookieService,
  LocalStorageIupics,
} from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { environment } from 'environments/environment';
import {iif, Observable, of, switchMap, throwError} from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { CompiereLanguage } from './models/compiere-language-json';
import {KeycloakService} from "keycloak-angular";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  #connectorService = inject(SecurityManagerService);
  #cookieService = inject(IupicsCookieService);
  #keycloakService = inject(KeycloakService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Global.activeXHRRequest++;

    if (this.#cookieService.checkLS(LocalStorageIupics.default_language)) {
      request = request.clone({
        setHeaders: {
          'X-Lang': `${
            (
              JSON.parse(
                this.#cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language)
              ) as CompiereLanguage
            ).iso_code
          }`,
        },
      });
    } else if (this.#connectorService.getIupicsUserAccount()) {
      if (this.#connectorService.getIupicsUserAccount().default_language) {
        request = request.clone({
          setHeaders: {
            'X-Lang': `${this.#connectorService.getIupicsUserAccount().default_language.iso_code}`,
          },
        });
      }
    }
    Global.lastActivity = new Date();
    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((err) => {
        return iif(
            () => err instanceof HttpErrorResponse && err.status === 401,
            of(this.#keycloakService.logout()).pipe(switchMap(() => throwError(() => err))),
            throwError(() => err))
      }),
      finalize(() => {
        if (Global.activeXHRRequest > 0) {
          Global.activeXHRRequest--;
        }
      })
    );
  }
}
